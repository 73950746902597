import {mapGetters, mapMutations} from "vuex";

export default {
    computed:{
      ...mapGetters({
          blockedName:'config/blockedName'
      })
    },
    methods:{
        closePopup(){
            this.changeBlocked({status: false, name:null})
        },
        ...mapMutations({
            changeBlocked: 'config/changeBlocked',
        })
    }
}